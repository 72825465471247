<template>
  <!-- Menus Restaurant Info Card -->
  <div class="menus-restaurant-info-card">
    <!-- Masthead -->
    <MenusMasthead />

    <!-- Menus Info View -->
    <MenusInfoView />
  </div>
</template>

<script>
import MenusInfoView from '../../MenusInfoView.vue'
import MenusMasthead from '../MenusMasthead.vue'

export default {
  name: 'MenusRestaurantInfoCard',
  components: { MenusMasthead, MenusInfoView }
}
</script>

<style scoped lang="sass">
.menus-restaurant-info-card
  min-width: 400px
  max-width: 400px
  @apply border-r border-grey-light rounded-sm p-3 my-3
</style>
