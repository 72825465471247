<template>
  <!-- Menus Main View -->
  <div class="menus-main-view">
    <div
      v-if="showHeader"
      class="xs:block md:hidden"
    >
      <!-- Menus Masthead -->
      <MenusMasthead />

      <!-- View Selector -->
      <BaseSelector
        v-if="showHeader"
        v-model="selectedItem"
        :items="navigationItems"
      />
    </div>
  </div>
</template>

<script>
import MenusMasthead from './menus/MenusMasthead'
import BaseSelector from './globals/BaseSelector'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'MenusMainView',
  components: {
    BaseSelector,
    MenusMasthead
  },
  props: {
    showHeader: {
      type: Boolean,
      required: false
    },
    selected: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedItem: {},
      navigationItems: [
        { id: 1, title: 'Menus', route: 'MenusMenusView' },
        { id: 2, title: 'Info', route: 'MenusInfoView' }
      ]
    }
  },
  computed: {
    ...mapGetters('UIModule', [
      'getCurrentTab'
    ])
  },
  watch: {
    selectedItem (newValue, oldValue) {
      this.SET_CURRENT_TAB(newValue.title)
    }
  },
  mounted () {
    this.selectedItem = this.navigationItems[0]
    window.addEventListener('resize', (e) => {
      if (window.innerWidth > 1024) {
        this.selectedItem = this.navigationItems[0]
      }
    })
  },
  methods: {
    ...mapMutations('UIModule', ['SET_CURRENT_TAB'])
  }
}
</script>

<style scoped lang="sass">
.menus-main-view
  @apply mx-auto

  @screen xs
    max-width: 100vw
    @apply w-full

  @screen md
    max-width: 80vw
</style>
