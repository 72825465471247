<template>
  <!-- Menus Restaurant Social -->
  <div class="menus-restaurant-social">
    <!-- Facebook -->
    <a
      v-if="getRestaurantFacebookURL"
      class="menus-restaurant-social--item"
      :href="getRestaurantFacebookURL"
      target="_blank"
    >
      <i class="fab fa-facebook-square" />
    </a>

    <!-- Instagram -->
    <a
      v-if="getRestaurantInstagramURL"
      class="menus-restaurant-social--item"
      :href="getRestaurantInstagramURL"
      target="_blank"
    >
      <i class="fab fa-instagram" />
    </a>

    <!-- Twitter -->
    <a
      v-if="getRestaurantTwitterURL"
      class="menus-restaurant-social--item"
      :href="getRestaurantTwitterURL"
      target="_blank"
    >
      <i class="fab fa-twitter" />
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MenusRestaurantSocial',
  computed: {
    ...mapGetters('MenusModule', [
      'getRestaurantFacebookURL',
      'getRestaurantInstagramURL',
      'getRestaurantTwitterURL'
    ])
  }
}
</script>

<style scoped lang="sass">
.menus-restaurant-social
  @apply flex justify-center items-center py-4

  &--item
    @apply w-16 text-3xl text-center text-grey-darker
</style>
