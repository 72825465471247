// Menus Module
export const MenusModule = {
  namespaced: true,
  state: {
    restaurant: {},
    selectedMenu: {
      categories: []
    },
    categoriesToShow: [],
    legendItems: []
  },
  getters: {
    // Check if restaurant exists
    hasRestaurant: state => state.restaurant.id,
    // Restaurant status
    getRestaurantStatus: state => state.restaurant.status,
    // Restaurant ID
    getRestaurantId: state => state.restaurant.id,
    // Restaurant Membership Date
    getRestaurantMembershipExpirationDate: state => state.restaurant.membershipExpires,
    // Restaurant Name
    getRestaurantName: state => state.restaurant.name,
    // Restaurant Description
    getRestaurantDescription: state => state.restaurant.description,
    // Restaurant Tagline
    getRestaurantTagline: state => state.restaurant.tagline,
    // Restaurant Logo
    getRestaurantLogo: state => state.restaurant.logoImage && state.restaurant.logoImage.thumbUrl
      ? state.restaurant.logoImage.thumbUrl : '',
    // Restaurant Cuisine Type
    getRestaurantCuisineType: state => state.restaurant.cuisineType,
    // Restaurant Background Images
    getRestaurantBackgroundImages: state => {
      const imageURLs = state.restaurant.images ? state.restaurant.images : []
      return imageURLs.map(i => i.image).map(image => image.fullUrl)
    },
    // Restaurant Phone Number
    getRestaurantPhoneNumber: state => state.restaurant.phone,
    // Restaurant Website
    getRestaurantWebsite: state => state.restaurant.websiteUrl,
    // Restaurant Facebook
    getRestaurantFacebookURL: state => state.restaurant.facebookUrl,
    // Restaurant Instagram
    getRestaurantInstagramURL: state => state.restaurant.instagramUrl,
    // Restaurant Twitter
    getRestaurantTwitterURL: state => state.restaurant.twitterUrl,
    // Restaurant Address
    getRestaurantAddress: state => {
      const { street1, city, state: state1, zipCode, country } = state.restaurant.address || {}
      return `${street1} ${city}, ${state1} ${zipCode} ${country}`
    },
    // Restaurant Latitude
    getRestaurantLatitude: state => state.restaurant.latitude,
    // Restaurant Longitude
    getRestaurantLongitude: state => state.restaurant.longitude,
    // Restaurant Hours of Operation
    getRestaurantHoursOfOperation: state => state.restaurant.timesOffered,
    // Restaurant Timezone
    getRestaurantTimezone: state => state.restaurant.timeZone ? state.restaurant.timeZone : 'America/Chicago',
    // Restaurant Menus
    getRestaurantMenus: state => state.restaurant.menus || [],
    // Selected Menu
    getSelectedMenu: state => state.selectedMenu,
    // Selected Menu Categories
    getSelectedMenuCategories: state => state.selectedMenu.categories || [],
    // Get Menu Categories To Show
    getMenuCategoriesToShow: state => state.categoriesToShow || [],
    // Get Legend Items
    getLegendItems: state => state.legendItems || [],
    // Returns the restaurant's internal identifier
    getRestaurantInternalIdentifier: state => state.restaurant.internalIdentifier
  },
  mutations: {
    // Set Legend Items Mutation
    SET_LEGEND_ITEMS (state, items) {
      state.legendItems = items
    },
    // Set Restaurant
    SET_RESTAURANT (state, restaurant) {
      state.restaurant = restaurant
    },
    // Set Selected Menu Mutation
    SET_SELECTED_MENU (state, menu) {
      state.selectedMenu = menu
    },
    SET_MENU_CATEGORIES (state, data) {
      state.restaurant.menus[
        state.restaurant.menus.indexOf(state.restaurant.menus.filter(x => x.id === data.menuId)[0])
      ].categories = data.categories

      const categoriesDisplay = []
      const categoriesLength = data.categories ? data.categories.length : 0

      // Loop through menus
      for (let i = 0; i < categoriesLength; i++) {
        const currentCategory = data.categories[i]
        let currentCategoryItemsLength = 0

        if (currentCategory.items) {
          currentCategoryItemsLength = currentCategory.items.length
        }
        // Add category title
        categoriesDisplay.push({
          id: i,
          type: 'title',
          name: currentCategory.name,
          description: currentCategory.description,
          style: currentCategory.style,
          items: currentCategory.items.filter(i => !i.isHidden) || []
        })

        // Loop through menu items
        for (let j = 0; j < currentCategoryItemsLength; j++) {
          const currentItem = data.categories[i].items[j]

          if (!currentItem.isHidden) {
            categoriesDisplay.push(currentItem)
          }
        }
      }

      state.categoriesToShow = categoriesDisplay
    }
  },
  actions: {
    // Set Default Menu
    setDefaultMenu ({ commit, state }) {
      commit('SET_SELECTED_MENU', state.restaurant.activeMenu)
    }
  }
}
