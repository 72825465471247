// Imports
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueCustomElement from 'vue-custom-element'

// CSS Imports
import '@/assets/css/tailwind.css'
import '@/assets/css/fonts.css'

// Plugins
Vue.use(VueCustomElement)
Vue.config.productionTip = false

App.store = store

// Create custom element
Vue.customElement('menu-widget', App)
