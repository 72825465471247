<template>
  <!-- Menus Masthead -->
  <div class="menus-masthead">
    <!-- Restaurant Logo -->
    <img
      v-if="getRestaurantLogo"
      alt="Logo"
      :src="getRestaurantLogo"
    >

    <!-- No Logo -->
    <div
      v-else
      class="menus-masthead--no-logo"
    >
      <i class="fas fa-store" />
    </div>

    <!-- Info -->
    <div class="menus-masthead--info">
      <!-- Restaurant Name -->
      <div class="menus-masthead--info--title">
        {{ getRestaurantName }}
      </div>

      <!-- Restaurant Cuisine Type -->
      <div class="menus-masthead--info--subtitle">
        {{ getRestaurantCuisineType }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MenusMasthead',
  computed: {
    ...mapGetters('MenusModule', [
      'getRestaurantLogo',
      'getRestaurantName',
      'getRestaurantCuisineType'
    ])
  }
}
</script>

<style scoped lang="sass">
.menus-masthead
  @apply bg-white flex items-center px-3 py-1

  img
    width: 55px
    height: 55px
    @apply object-cover rounded-sm

  &--no-logo
    @apply border border-grey px-3 py-2 rounded-sm text-xl

  &--info
    @apply py-2 px-3 flex flex-col justify-center

    &--title
      font-weight: 600
      @apply text-lg

    &--subtitle
      @apply text-grey-darker
</style>
