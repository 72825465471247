<template>
  <!-- Base Selector -->
  <div class="base-selector">
    <div
      v-for="item in items"
      :key="item.id"
      class="base-selector--item"
      @click="selectItem(item)"
    >
      <div
        :class="{ 'base-selector--item--selected': isItemSelected(item) }"
      >
        {{ item.title }}
      </div>

      <!-- Selector Line -->
      <div
        v-if="isItemSelected(item)"
        class="base-selector--line"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSelector',
  props: {
    // Model
    value: {
      type: Object,
      required: false,
      default: () => {}
    },
    // Items
    items: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  methods: {
    // Select Item
    selectItem (item) {
      this.$emit('input', item)
    },
    // Is Item Selected
    isItemSelected (item) {
      return this.value.id === item.id
    }
  }
}
</script>

<style scoped lang="sass">
.base-selector
  @apply bg-white flex items-center

  &--item
    @apply border-b border-t border-grey-light cursor-pointer flex flex-1 items-center justify-center py-4 relative text-sm tracking-wide uppercase

    &:first-child
      @apply border-r

    &--selected
      transition: all 0.3s ease-in-out
      @apply font-semibold text-brand

  &--line
    animation: grow 0.3s ease-out
    max-width: 250px
    @apply absolute border-b-4 border-brand bottom-0 w-5/6

@keyframes grow
  0%
    transform: scale(0.1)

  100%
    transform: scale(1)
</style>
