export default {
  id: 1,
  name: 'My Healthy Kitchen - Houston',
  logoUrl: [{ fullUrl: 'https://static.independent.co.uk/s3fs-public/thumbnails/image/2019/12/31/15/istock-854725402.jpg?w968' }],
  websiteUrl: 'https://www.example.com',
  description: 'This is the best restaurant ever. Awesome Sushi, awesome Pizza, awesome staff!',
  backgroundImageUrls: [
    { fullUrl: 'https://cdn.vox-cdn.com/thumbor/MQFU_Dn1SKLpD6R0-rB_-90nZhc=/0x0:960x673/620x465/filters:focal(404x261:556x413)/cdn.vox-cdn.com/uploads/chorus_image/image/62771444/realgood_stuff.1546466894.jpg' },
    { fullUrl: 'https://static.independent.co.uk/s3fs-public/thumbnails/image/2019/12/31/15/istock-854725402.jpg?w968' },
    { fullUrl: 'https://cdn.vox-cdn.com/thumbor/MQFU_Dn1SKLpD6R0-rB_-90nZhc=/0x0:960x673/620x465/filters:focal(404x261:556x413)/cdn.vox-cdn.com/uploads/chorus_image/image/62771444/realgood_stuff.1546466894.jpg' },
    { fullUrl: 'https://static.independent.co.uk/s3fs-public/thumbnails/image/2019/12/31/15/istock-854725402.jpg?w968' }],
  address: {
    street1: '123 Main Street',
    street2: null,
    city: 'Houston',
    state: 'TX',
    zipCode: '77007',
    country: 'USA'
  },
  membershipExpires: new Date('2021-12-17T03:24:00'),
  // phone: '+18325551234',
  hoursOfOperation: {
    sunday: [{
      from: 49000,
      to: 75600
    }],
    monday: [{
      from: 43000,
      to: 82800
    }],
    tuesday: [{
      from: 44000,
      to: 82800
    }],
    wednesday: [{
      from: 46800,
      to: 99999
    }],
    thursday: [{
      from: 46800,
      to: 120000
    }],
    friday: [{
      from: 46800,
      to: 82800
    }]
  },
  category: 1,
  facebookUrl: 'https://facebook.com',
  instagramUrl: null,
  twitterUrl: 'https://twitter.com',
  latitude: 29.622731,
  longitude: -95.606339,
  cuisineType: 'Organic and vegan options',
  timezone: {
    identifier: 'America/Chicago'
  },
  status: 'active',
  menus: [{
    uuid: '28867ece-aa0b-47e5-af34-f390b76d5d97',
    name: 'Brunch',
    timesOffered: {
      sunday: {
        from: 43200,
        to: 50400
      },
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: {
        from: 43200,
        to: 50400
      }
    },
    categories: [{
      uuid: 'c38677f9-7d2f-4d0b-856a-fe2eb03bc0b7',
      name: 'JUICES & SMOOTHIES',
      description: null,
      isSubCategory: false,
      items: [{
        uuid: '14c8092c-7190-4a0c-880c-bbf66c80274d',
        imageURLs: null,
        title: 'Bright Eyes',
        description: 'apple, ginger, beet, pineapple, turmeric, carrot',
        legendItem: null,
        price: '$8.00',
        calories: 170,
        isSoldOut: false,
        isHidden: true,
        addOns: null
      },
      {
        uuid: '42aa909a-2055-4453-a5f9-34747a3dea07',
        imageURLs: null,
        title: 'Banana & Date Smoothie',
        description: 'almond milk, almond butter, flaxseed',
        legendItem: null,
        price: '$7.00',
        calories: 460,
        isSoldOut: false,
        isHidden: false,
        addOns: null
      },
      {
        uuid: '418ebc72-9b54-466a-abd6-386dafae00a2',
        imageURLs: null,
        title: 'Kale Aid',
        description: 'kale, ginger, apple, celery, cucumber, lemon',
        legendItem: null,
        price: '$9.00',
        calories: 100,
        isSoldOut: false,
        isHidden: false,
        addOns: null
      },
      {
        uuid: 'a980cfe1-8649-417a-8e9e-46f87bbfff8b',
        imageURLs: ['https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/keto-smoothie-vertical-1540502290.jpg?crop=1xw:1xh;center,top&resize=980:*'],
        title: 'Kale & Coconut Smoothie',
        description: 'banana, pineapple, lemon, ginger, honey',
        legendItem: 0,
        price: '$6.00',
        calories: 490,
        isSoldOut: false,
        isHidden: false,
        addOns: null
      }
      ]
    },
    {
      uuid: 'c4500b18-6616-4f44-b88b-e37c70060320',
      name: 'BREAKFAST',
      description: null,
      isSubCategory: true,
      items: [{
        uuid: '937b7aa3-5632-4d2b-9c6f-49beefcf30f9',
        imageURLs: null,
        title: 'Blueberry Muffin',
        description: null,
        legendItem: null,
        price: '$6.00',
        calories: 490,
        isSoldOut: false,
        isHidden: false,
        addOns: null
      }, {
        uuid: '0d5e2b2e-4c02-4319-aeef-a104db131dc2',
        imageURLs: null,
        title: 'Banana Pancakes',
        description: 'quinoa, greek yogurt, maple syrup',
        legendItem: null,
        price: '$5.00',
        calories: 550,
        isSoldOut: false,
        isHidden: false,
        addOns: null
      }, {
        uuid: '6d081c24-bf30-44e2-9e36-bdf0692fcadc',
        imageURLs: null,
        title: 'Sunrise Bowl*',
        description: 'organic sunny side up eggs, ancient grains, sweet potato, grilled portobello, avocado, hemp seed, cilantro pumpkin seed pesto. organic sunny side up eggs, ancient grains, sweet potato, grilled portobello, avocado, hemp seed, cilantro pumpkin seed pesto',
        legendItem: null,
        price: '$14.00',
        calories: 640,
        isSoldOut: false,
        isHidden: false,
        addOns: null
      }, {
        uuid: 'e108de07-c975-4c8f-bc0f-9d393fef6ea9',
        imageURLs: null,
        title: 'Egg & Chicken Sausage Foo Bar Sandwich*',
        description: 'manchego, organic tomato, smashed avocado',
        legendItem: 0,
        price: '$13.00',
        calories: 660,
        isSoldOut: true,
        isHidden: false,
        addOns: null
      }, {
        uuid: 'bd1a2ea5-bd1a-40a0-b066-287af8a79e66',
        imageURLs: null,
        title: 'Smashed Avocado Toast*',
        description: 'organic sunny side up eggs, smoked gouda, sesame, thyme',
        legendItem: null,
        price: '$11.00',
        calories: 590,
        isSoldOut: false,
        isHidden: false,
        addOns: [
          {
            uuid: 'e458348c-8c9a-498d-a61b-dba91006c11c',
            name: 'Add bacon',
            legendItem: null,
            price: '$3.00',
            isSoldOut: false,
            isHidden: false,
            calories: 300,
            definesPriceForItem: false
          },
          {
            uuid: '425cacbf-9ec1-4895-8699-23c411aaa352',
            name: 'Add extra egg',
            legendItem: null,
            price: '$2.00',
            isSoldOut: false,
            isHidden: false,
            calories: 150,
            definesPriceForItem: false
          }
        ]
      }]
    }
    ],
    disclaimer: '* These items may be served raw or undercooked. Consuming raw or undercooked meats, poultry, seafood, shellfish, or eggs may increase your risk of foodborne illness, especially if you have certain medical conditions.'
  },
  {
    uuid: 'f333ed34-1174-47e0-934c-dd5ad33e13bc',
    name: 'Lunch & Dinner',
    timesOffered: {
      sunday: {
        from: 43200,
        to: 75600
      },
      monday: {
        from: 46800,
        to: 82800
      },
      tuesday: {
        from: 46800,
        to: 82800
      },
      wednesday: {
        from: 46800,
        to: 82800
      },
      thursday: {
        from: 46800,
        to: 82800
      },
      friday: {
        from: 46800,
        to: 82800
      },
      saturday: {
        from: 46800,
        to: 82800
      }
    },
    categories: [{
      uuid: '6771a6ef-c63b-44a4-9628-cdb1dd2cba1d',
      name: 'STARTERS',
      description: null,
      isSubCategory: false,
      items: [{
        uuid: '328c40e0-a514-4147-aae9-d02bd3ae1937',
        imageURLs: null,
        title: 'Roasted Brussels Sprouts',
        description: 'mushroom, miso sesame vinaigrette, chili thread',
        legendItem: null,
        price: '$9.00',
        calories: null,
        isSoldOut: false,
        isHidden: false,
        addOns: null
      },
      {
        uuid: '2dcbf2f8-456c-49eb-b796-7815e34401a3',
        imageURLs: null,
        title: 'Herb Hummus',
        description: 'cucumber, organic tomato, onion, olive, feta, lemon oregano vinaigrette & house-made pita',
        legendItem: null,
        price: '$10.00',
        calories: null,
        isSoldOut: false,
        isHidden: false,
        addOns: null
      }
      ]
    },
    {
      uuid: '7163791e-51e1-410d-8b8d-d08f736ca3a4',
      name: 'SALADS & BOWLS',
      description: 'organic tofu +$4 / grilled chicken +$4',
      isSubCategory: false,
      items: [{
        uuid: 'a9fdf5c1-11ca-43a2-9b36-f3ac67af5b46',
        imageURLs: null,
        title: 'Chopped Salad',
        description: 'medjool date, dried cranberry, jicama, organic apple, manchego, farro, marcona almond, champagne vinaigrette',
        legendItem: null,
        price: '$12.50',
        calories: 490,
        isSoldOut: false,
        isHidden: false,
        addOns: null
      }, {
        uuid: 'ac38aa8a-316b-4219-98ed-06ce9b75df6e',
        imageURLs: null,
        title: 'Organic Tuscan Kale Salad',
        description: 'lemon, garlic, parmigiano reggiano, breadcrumb',
        legendItem: null,
        price: '$10.50',
        calories: null,
        isSoldOut: false,
        isHidden: false,
        addOns: null
      }, {
        uuid: '41495584-daf2-4f69-b46a-2264c96d98e1',
        imageURLs: null,
        title: 'Teriyaki Quinoa Bowl',
        description: 'broccoli, rainbow carrot, bok choy, mushroom, brown rice, avocado, toasted sesame',
        legendItem: null,
        price: '$14.00',
        calories: null,
        isSoldOut: false,
        isHidden: false,
        addOns: null
      }, {
        uuid: '2d1a5740-cc93-4290-a97c-4af4dce3900e',
        imageURLs: null,
        title: 'Spaghetti Squash Casserole',
        description: 'crushed organic DiNapoli tomato, caramelized onion, zucchini, fresh mozzarella',
        legendItem: null,
        price: '$14.00',
        calories: null,
        isSoldOut: false,
        isHidden: false,
        addOns: null
      }, {
        uuid: 'd9f5c568-a219-4d3b-aee2-4cf4d1d312e1',
        imageURLs: null,
        title: 'Ancient Grains',
        description: 'miso sesame glazed sweet potato, turmeric, charred onion, snap pea, grilled portobello, avocado, hemp seed',
        legendItem: null,
        price: '$14.00',
        calories: null,
        isSoldOut: false,
        isHidden: false,
        addOns: null
      }]
    }
    ],
    disclaimer: '* These items may be served raw or undercooked. Consuming raw or undercooked meats, poultry, seafood, shellfish, or eggs may increase your risk of foodborne illness, especially if you have certain medical conditions.'
  }
  ]
}
