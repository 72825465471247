// UI Module
export const UIModule = {
  namespaced: true,
  state: {
    showHeader: false,
    currentTab: null
  },
  getters: {
    // Should show header
    getCurrentTab: state => state.currentTab,
    getShowHeader: state => state.showHeader
  },
  mutations: {
    // Set Show Header Mutation
    SET_SHOW_HEADER (state, val) {
      state.showHeader = val
    },
    SET_CURRENT_TAB (state, val) {
      state.currentTab = val
    }
  }
}
