<template>
  <!-- Menus Category Item -->
  <div class="menus-category-item">
    <!-- Legend Item -->
    <div class="w-6 h-6 mr-2">
      <img
        v-if="getLegendItem(item.legendItemId) !== null"
        class="h-full w-full"
        :src="getLegendItem(item.legendItemId)"
      >
    </div>

    <!-- Content -->
    <div class="menus-category-item--content">
      <!-- Title + Calories -->
      <div class="menus-category-item--title">
        <span :class="{ 'text-grey-darker': item.isSoldOut }">
          {{ item.name }}
          <span
            v-if="item.isSoldOut"
            class="text-danger font-regular text-sm italic"
          >
            (Sold Out)
          </span>
        </span>

        <span
          v-if="item.calories"
          class="menus-category-item--calories"
        >
          ({{ item.calories }} cal)
        </span>

        <!-- Description + Price -->
        <span
          class="menus-category-item--description"
        >
          {{ item.description }}
          <span v-if="item.price && !item.hidePrice">
            - {{ item.price | price }}
          </span>
        </span>

        <!-- Item Addons -->
        <div
          v-for="(addon, index) in getAddonItems"
          :key="index"
          class="font-normal italic text-sm ml-3"
        >
          {{ addon.name }}
          <span v-if="addon.calories"> ({{ addon.calories }} cal)</span>
          <span v-if="addon.description">{{ addon.description }}</span>
          <span v-if="!addon.hidePrice">- {{ addon.price | price }}</span>
        </div>
      </div>
    </div>

    <!-- Image -->
    <div
      v-if="item.image && item.image.thumbUrl"
      class="menus-category-item--image"
    >
      <img
        :src="item.image.thumbUrl"
        @click="selectImage"
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MenusMenuCategoryItem',
  filters: {
    price (val) {
      if (isNaN(val)) return ''

      return '$' + val / 100
    }
  },
  props: {
    item: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters('MenusModule', ['getLegendItems']),
    // Get Addon Items
    getAddonItems () {
      if (!this.item.addOns) return []
      return this.item.addOns.filter(addon => !addon.isHidden)
    }
  },
  methods: {
    // Select Image
    selectImage () {
      this.$emit('selectImage')
    },
    // Get legend item
    getLegendItem (item) {
      const foundLegendItem = this.getLegendItems.find(i => i.id === item)
      return foundLegendItem && foundLegendItem.image && foundLegendItem.image.fullUrl
        ? foundLegendItem.image.fullUrl : null
    }
  }
}
</script>

<style scoped lang="sass">
.menus-category-item
  @apply flex p-2 border-b border-grey-light

  &:last-child
    @apply border-b-0

  &--star
    object-fit: cover
    @apply w-1/12

  &--content
    @apply w-full flex flex-wrap items-center

  &--title
    @apply font-bold items-center

  &--calories
    @apply text-sm font-medium

  &--description
    @apply font-medium text-sm

  &--image
    @apply w-2/12 flex ml-1 cursor-pointer

    img
      height: 50px
      width: 50px
      object-fit: cover
</style>
