import { render, staticRenderFns } from "./MenusRestaurantInfo.vue?vue&type=template&id=6e56b5ee&scoped=true&"
import script from "./MenusRestaurantInfo.vue?vue&type=script&lang=js&"
export * from "./MenusRestaurantInfo.vue?vue&type=script&lang=js&"
import style0 from "./MenusRestaurantInfo.vue?vue&type=style&index=0&id=6e56b5ee&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e56b5ee",
  null
  
)

export default component.exports