<template>
  <!-- Base Select -->
  <div class="base-select">
    <!-- Selected -->
    <div
      class="base-select--selected"
      @click="showDropdown = true"
    >
      <slot
        v-if="$scopedSlots['selected']"
        name="selected"
        :item="value"
      />

      <div
        v-else
      >
        {{ value.name }}
      </div>

      <i
        class="fas fa-chevron-down ml-3"
      />
    </div>

    <!-- Dropdown -->
    <div
      v-if="showDropdown"
      v-on-dismiss="{ watch: showDropdown, callback: closeDropdown }"
      class="base-select--dropdown"
    >
      <div
        v-for="item in items"
        :key="item.value"
        class="base-select--dropdown--item"
        :class="{ 'base-select--dropdown--item--selected': item.uuid === value.uuid }"
        @click="selectItem(item)"
      >
        <slot
          v-if="$scopedSlots['item']"
          name="item"
          :item="item"
        />

        <div v-else>
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueDismiss from '../../mixins/vueDismiss'

export default {
  name: 'BaseSelect',
  mixins: [vueDismiss],
  props: {
    // Model
    value: {
      type: Object,
      required: false,
      default: () => {}
    },
    // Items
    items: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      showDropdown: false
    }
  },
  methods: {
    // Select Item
    selectItem (item) {
      this.$emit('input', item)
      this.$emit('select', item)
      this.showDropdown = false
    },
    // Close Dropdown
    closeDropdown () {
      this.showDropdown = false
    }
  }
}
</script>

<style scoped lang="sass">
.base-select
  &--selected
    width: max-content
    @apply cursor-pointer flex items-center text-brand text-lg

  &--dropdown
    animation: appear 0.2s ease-in-out
    @apply absolute bg-white border border-grey-light mt-2 rounded-sm shadow-xl z-50

    &--item
      @apply cursor-pointer px-3 py-2

      &:hover
        @apply bg-grey-lightest

      &--selected
        @apply text-brand font-bold

@keyframes appear
  0%
    opacity: 0
    transform: translateY(-5px)

  100%
    opacity: 1
</style>
