<template>
  <!-- Menus Menus View -->
  <div class="menus-menus-view">
    <!-- Restaurant Info Card (Desktop only) -->
    <MenusRestaurantInfoCard
      v-if="getShowHeader"
      class="w-2/3 xs:hidden md:block"
    />

    <div
      v-if="getRestaurantMenus.length"
      class="xs:pr-4 md:px-4"
    >
      <!-- Menu Selector -->
      <BaseSelect
        v-model="selectedMenu"
        :items="getRestaurantMenus"
        class="mx-3 py-5"
        @select="menu => loadCategories(menu, true)"
      >
        <template #item="{ item }">
          {{ item.name }}

          <div v-if="typeof getMenuTimeOffered(item) === 'object' && getMenuTimeOffered(item).length">
            <div
              v-for="(time, index) in getMenuTimeOffered(item)"
              :key="index"
              class="text-sm text-grey-darker"
            >
              {{ time }}
            </div>
          </div>

          <div
            v-else
            class="text-sm text-grey-darker"
          >
            Not offered today
          </div>
        </template>
      </BaseSelect>

      <!-- Category List -->
      <BaseLoadingSpinner
        v-if="isCategoryLoading"
        class="mb-4"
      />

      <div v-else>
        <!-- Description + Price -->
        <div class="px-5 mb-4">
          <span
            v-if="selectedMenu.description"
          >
            {{ selectedMenu.description }}
          </span>
          <span v-if="selectedMenu.price !== null"> - ${{ selectedMenu.price / 100 }}</span>
        </div>

        <!-- Categories -->
        <MenusMenuCategoryList />
      </div>
    </div>

    <!-- No menus message -->
    <div
      v-else
      class="menus-menus-view--no-restaurants"
    >
      No menus on this restaurant yet.
    </div>
  </div>
</template>

<script>
import { getCategoriesByInternalIdentifierAndMenuId } from '@/api/RestaurantsService'
import { mapGetters, mapMutations } from 'vuex'
import MenusMenuCategoryList from './menus/MenusMenuCategoryList'
import BaseSelect from './globals/BaseSelect'
import MenusRestaurantInfoCard from './menus/menus-info/MenusRestaurantInfoCard'
import BaseLoadingSpinner from '../components/globals/BaseLoadingSpinner.vue'
import moment from 'moment-timezone'

export default {
  name: 'MenusMenusView',
  components: {
    MenusRestaurantInfoCard,
    BaseSelect,
    MenusMenuCategoryList,
    BaseLoadingSpinner
  },
  data () {
    return {
      isCategoryLoading: false,
      days: {
        sunday: 0,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6
      }
    }
  },
  computed: {
    ...mapGetters('MenusModule', [
      'getSelectedMenu',
      'getRestaurantMenus',
      'getSelectedMenuCategories',
      'getRestaurantTimezone',
      'getRestaurantId',
      'getRestaurantInternalIdentifier'
    ]),
    ...mapGetters('UIModule', ['getShowHeader']),
    selectedMenu: {
      get () {
        return this.getSelectedMenu
      },
      set (val) {
        this.SET_SELECTED_MENU(val)
      }
    }
  },
  mounted () {
    this.loadCategories(this.getSelectedMenu, false)
  },
  methods: {
    ...mapMutations('MenusModule', ['SET_SELECTED_MENU', 'SET_MENU_CATEGORIES', 'SET_LEGEND_ITEMS']),
    // Get Menu Time Offered Today
    getMenuTimeOffered (menu) {
      if (!menu.timesOffered) return 'Not offered today'

      const result = []

      const today = moment().format('dddd').toLowerCase()

      const menuOfferingToday = menu.timesOffered.filter(time => time.dayOfWeek === this.days[today])

      if (!menuOfferingToday.length) {
        return []
      }

      for (let i = 0; i < menuOfferingToday.length; i++) {
        const currentTime = menuOfferingToday[i]

        const beginOfDay = moment().tz(this.getRestaurantTimezone).startOf('day').add(currentTime.open, 'seconds')
        const endOfDay = moment().tz(this.getRestaurantTimezone).startOf('day').add(currentTime.close, 'seconds')
        result.push(`${beginOfDay.format('h:ss a')} - ${endOfDay.format('h:ss a')}`)
      }

      return result
    },
    async loadCategories (menu, load) {
      this.isCategoryLoading = true

      if (load) {
        const response = await getCategoriesByInternalIdentifierAndMenuId(this.getRestaurantInternalIdentifier, menu.id)
        this.SET_MENU_CATEGORIES({ menuId: menu.id, categories: response.categories })
        this.SET_LEGEND_ITEMS(response.legendItems)
      } else {
        this.SET_MENU_CATEGORIES({ menuId: menu.id, categories: menu.categories })
      }

      this.isCategoryLoading = false
    }
  }
}
</script>

<style scoped lang="sass">
.menus-menus-view
  @apply bg-white flex

  &--no-restaurants
    @apply py-24 text-center w-full

    @screen xs
      @apply text-lg

    @screen md
      @apply text-2xl

  &--description
    @apply font-bold text-lg
</style>
