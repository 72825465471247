<template>
  <!-- Menus Menu Categories List -->
  <div class="w-full">
    <div
      v-if="!getMenuCategoriesToShow.length"
      class="p-5 text-2xl italic w-full"
    >
      No items on this menu
    </div>

    <div
      v-else
      class="menus-category-list"
    >
      <div
        v-for="(category, index) in getMenuCategoriesToShow"
        :key="category.uuid"
        class="menus-category-list--category"
      >
        <!-- Title -->
        <div
          v-if="category.type === 'title'"
        >
          <div
            class="menus-category-list--title"
            :class="getCategoryStyle(category.style)"
          >
            {{ category.name }}

            <div
              v-if="category.description"
              class="font-normal text-sm"
            >
              ({{ category.description }})
            </div>
          </div>
        </div>

        <!-- Items -->
        <MenusMenuListItem
          v-else
          :key="category.id"
          :item="category"
          @selectImage="openImageSlideshow(category, index)"
        />
      </div>
    </div>

    <!-- Legend Items -->
    <span
      v-if="getLegendItems.length"
      class="flex flex-wrap text-xs mx-5 mt-10 italic"
    >
      <span
        v-for="(legendItem, index) in getLegendItems"
        :key="index"
        class="flex items-center mr-5 my-2 xs:w-full md:w-auto"
      >
        <img
          v-if="legendItem.image && legendItem.image.thumbUrl"
          :src="legendItem.image.thumbUrl"
          class="w-5 h-5 mr-2"
        >
        {{ legendItem.name }}
      </span>
    </span>

    <!-- Disclaimer -->
    <div class="text-xs mx-5 italic mb-4">
      {{ getSelectedMenu.disclaimer }}
    </div>

    <!-- Images Slideshow -->
    <BaseImageSlideshow
      v-if="showImageSlideshow"
      v-model="showImageSlideshow"
      :images="[selectedImageCategory.image.fullUrl]"
    >
      <template #title>
        {{ selectedImageCategory.name }}
      </template>
    </BaseImageSlideshow>
  </div>
</template>

<script>
import MenusMenuListItem from './MenusMenuCategoryListItem'
import { mapGetters } from 'vuex'
import BaseImageSlideshow from '../globals/BaseImageSlideshow'

export default {
  name: 'MenusMenuCategoryList',
  components: {
    BaseImageSlideshow,
    MenusMenuListItem
  },
  data () {
    return {
      showImageSlideshow: false,
      selectedImageCategory: {},
      categories: []
    }
  },
  computed: {
    ...mapGetters('MenusModule', [
      'getSelectedMenu',
      'getSelectedMenuCategories',
      'getMenuCategoriesToShow',
      'getLegendItems'
    ])
  },
  methods: {
    openImageSlideshow (category) {
      this.selectedImageCategory = category
      this.showImageSlideshow = true
    },
    getCategoryStyle (style) {
      return style === 'header' ? 'font-bold border-b-4 text-2xl' : 'border-b-2 font-bold text-xl'
    }
  }
}
</script>

<style scoped lang="sass">
.menus-category-list
  @apply bg-white
  column-width: 300px

  @screen xs
    column-count: 1
    @apply w-full mx-0

  @screen sm
    column-count: 2
    @apply mx-auto w-full

  @screen md
    column-count: 3

  &--category
    display: inline-block
    @screen xs
      @apply w-full px-0 mr-0

    @screen md
      @apply px-3

  &--title
    @apply border-grey m-2 tracking-wide py-1 w-full
</style>
