// Imports
import axios from 'axios'

// Create Axios Instance
const AxiosRestaurant = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  headers: {
    Accept: 'application/json'
  }
})

// Request Interceptor
AxiosRestaurant.interceptors.request.use(
  (config) => {
    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

// Response Interceptor
AxiosRestaurant.interceptors.response.use(
  async (response) => {
    return response
  },

  async (error) => {
    return Promise.reject(error)
  }
)

export default AxiosRestaurant
