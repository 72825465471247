<template>
  <!-- Menus Restaurant Info -->
  <div class="menus-restaurant-info">
    <!-- Restaurant Tagline -->
    <div
      v-if="getRestaurantTagline"
      class="px-5 py-2 text-grey-darker italic xs:pt-3 md:pt-2"
    >
      {{ getRestaurantTagline }}
    </div>

    <!-- Restaurant Description -->
    <div
      v-if="getRestaurantDescription"
      class="border-b border-grey-light pb-5 py-4 px-5 text-black text-sm"
    >
      {{ getRestaurantDescription }}
    </div>

    <!-- Phone Number -->
    <div
      v-if="getRestaurantPhoneNumber"
      class="menus-restaurant-info--item"
    >
      <div class="w-4 mr-5 text-center">
        <i class="fas fa-phone-alt text-lg text-grey" />
      </div>

      <a :href="`tel:${getRestaurantPhoneNumber}`">
        {{ getRestaurantPhoneNumber }}
      </a>
    </div>

    <!-- Website -->
    <div
      v-if="getRestaurantWebsite"
      class="menus-restaurant-info--item"
    >
      <div class="w-4 mr-5 text-center">
        <i class="fas fa-globe text-lg text-grey" />
      </div>

      <div>
        <a
          class="text-blue"
          :href="getRestaurantWebsite"
        >
          {{ getRestaurantWebsite }}
        </a>
      </div>
    </div>

    <!-- Address -->
    <div
      v-if="getRestaurantAddress"
      class="menus-restaurant-info--item"
    >
      <div class="w-4 mr-5 text-center">
        <i class="fas fa-map-marker text-lg text-grey" />
      </div>

      <div>
        {{ getRestaurantAddress }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MenusRestaurantInfo',
  data () {
    return {
      showErrorImage: false
    }
  },
  computed: {
    ...mapGetters('MenusModule', [
      'getRestaurantTagline',
      'getRestaurantDescription',
      'getRestaurantPhoneNumber',
      'getRestaurantWebsite',
      'getRestaurantAddress',
      'getRestaurantLatitude',
      'getRestaurantLongitude'
    ])
  }
}
</script>

<style scoped lang="sass">
.menus-restaurant-info
  @apply bg-white

  &--item
    @apply border-b border-grey-light flex px-5 py-2 items-center
</style>
