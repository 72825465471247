<template>
  <!-- Menus Footer -->
  <div class="footer">
    <a
      href="https://getminu.com"
      target="_blank"
    >
      <img
        style="height: 20px"
        src="https://storage.googleapis.com/minu_cdn_staging/static/images/powered-by-minu.png"
      >
    </a>
  </div>
</template>

<script>
export default {
  name: 'MenusFooter'
}

</script>

<style scoped lang="sass">
.footer
  @apply flex items-center justify-center flex-1 h-full py-5 bg-white
</style>
