<template>
  <!-- Menus Info View -->
  <div class="menus-info-view">
    <!-- Restaurant Info -->
    <MenusRestaurantInfo />

    <!-- Restaurant Social -->
    <MenusRestaurantSocial />

    <!-- Restaurant Opening Hours -->
    <MenusRestaurantHours />
  </div>
</template>

<script>
import MenusRestaurantInfo from './menus/menus-info/MenusRestaurantInfo'
import MenusRestaurantSocial from './menus/menus-info/MenusRestaurantSocial'
import MenusRestaurantHours from './menus/menus-info/MenusRestaurantHours'

export default {
  name: 'MenusInfoView',
  components: { MenusRestaurantHours, MenusRestaurantSocial, MenusRestaurantInfo }
}
</script>

<style scoped lang="sass">
.menus-info-view
  @apply bg-white
</style>
