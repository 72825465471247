<template>
  <!-- Base Image Slider -->
  <div
    id="base-image-slider"
    class="base-image-slider"
  >
    <!-- Cloudy Overlay Left -->
    <div
      v-if="thresholdPassed"
      class="base-image-slider--overlay base-image-slider--overlay--left"
      :style="getOverlayPositionLeft"
    >
      <i
        class="fas fa-chevron-left"
        @click="moveSlider('left')"
      />
    </div>

    <!-- Cloudy Overlay Right -->
    <div
      v-if="!limitReached"
      class="base-image-slider--overlay base-image-slider--overlay--right"
      :style="getOverlayPositionRight"
    >
      <i
        class="fas fa-chevron-right"
        @click="moveSlider('right')"
      />
    </div>

    <!-- Image List -->
    <div
      v-for="(image, index) in images"
      :key="index"
      class="base-image-slider--image flex-shrink-0"
    >
      <img
        v-if="!imagesHaveErrors[index]"
        alt="Image"
        :src="image"
        class="cursor-pointer"
        @error="setImageError(index)"
        @click="$emit('select', index)"
      >

      <div
        v-else
        class="base-image-slider--error"
      >
        <i class="fas fa-unlink text-5xl" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseImageSlider',
  props: {
    images: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      scrollPositionLeft: 0,
      scrollPositionRight: 0,
      thresholdPassed: false,
      limitReached: false,
      windowWidth: 0,
      imagesHaveErrors: []
    }
  },
  computed: {
    // Get Overlay Position Left
    getOverlayPositionLeft () {
      return {
        position: 'absolute',
        left: this.scrollPositionLeft + 'px'
      }
    },
    // Get Overlay Position
    getOverlayPositionRight () {
      return {
        position: 'absolute',
        left: this.scrollPositionRight + 'px'
      }
    }
  },
  mounted () {
    this.initiateSlider()
  },
  created () {
    // Add event listened for resize
    window.addEventListener('resize', () => {
      const imageSlider = document.getElementById('base-image-slider')
      this.setSliderValues(imageSlider)
    })
  },
  destroyed () {
    const imageSlider = document.getElementById('base-image-slider')

    if (imageSlider) {
      // Remove event listeners
      imageSlider.removeEventListener('scroll', (e) => e)
    }

    window.removeEventListener('resize', e => e)
  },
  methods: {
    // Initiate Slider
    initiateSlider () {
      const imageSlider = document.getElementById('base-image-slider')

      // Set Event Listener for scrolling
      this.setSliderValues(imageSlider)

      imageSlider.addEventListener('scroll', (e) => {
        this.setSliderValues(imageSlider)
      })
    },
    // Set Slider Values
    setSliderValues (slider) {
      this.scrollPositionLeft = slider.scrollLeft
      this.scrollPositionRight = slider.scrollLeft + slider.clientWidth - 23
      this.limitReached = slider.scrollLeft >= slider.scrollWidth - slider.clientWidth
      this.thresholdPassed = slider.scrollLeft > 50
      this.windowWidth = window.innerWidth
    },
    moveSlider (direction) {
      const movement = direction === 'right' ? 400 : -400

      if (!this.limitReached) {
        const imageSlider = document.getElementById('base-image-slider')
        imageSlider.scrollTo({ top: 0, left: imageSlider.scrollLeft + movement, behavior: 'smooth' })
      }
    },
    // Set Image Error
    setImageError (index) {
      this.imagesHaveErrors[index] = true
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped lang="sass">
.base-image-slider
  height: 240px
  @apply flex relative overflow-x-auto bg-grey-light

  img
    width: 400px
    height: 100%
    @apply object-cover

  &--image
    @apply mr-1

    &:last-child
      @apply mr-0

  &--error
    width: 400px
    @apply flex justify-center items-center bg-white h-full mr-1

  &--overlay
    width: 25px
    animation: appear 0.4s ease-in
    @apply items-center h-full

    @screen xs
      @apply hidden

    @screen md
      @apply flex

    i
      @apply cursor-pointer text-4xl text-black

@keyframes appear
  0%
    opacity: 0

  100%
    opacity: 1
</style>
