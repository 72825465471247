<template>
  <!-- Base Image Slideshow -->
  <div class="base-image-slideshow">
    <div
      v-on-dismiss="{ callback: closeSlideshow }"
      class="mx-auto"
    >
      <!-- Image Wrapper -->
      <div class="base-image-slideshow--image--wrapper">
        <!-- Previous Image -->
        <div
          class="base-image-slideshow--image--direction"
          :class="{ 'base-image-slideshow--image--direction--disabled': !imageIndex }"
          @click="goToImage(-1)"
        >
          <i class="fas fa-chevron-circle-left" />
        </div>

        <!-- Title + Image -->
        <div class="base-image-slideshow--image">
          <!-- Title -->
          <div class="base-image-slideshow--title">
            <!-- Close Slideshow -->
            <i
              class="cursor-pointer fas fa-times-circle"
              @click="closeSlideshow"
            />

            <slot name="title" />
          </div>

          <!-- Image -->
          <img
            class="base-image-slideshow--image--image"
            :src="getCurrentImage"
          >
        </div>

        <!-- Next Image -->
        <div
          class="base-image-slideshow--image--direction"
          :class="{ 'base-image-slideshow--image--direction--disabled': imageIndex === images.length - 1 }"
          @click="goToImage(1)"
        >
          <i class="fas fa-chevron-circle-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueDismiss from '../../mixins/vueDismiss'

export default {
  name: 'BaseImageSlideshow',
  mixins: [vueDismiss],
  props: {
    // Images
    images: {
      type: Array,
      required: false,
      default: () => []
    },
    // Current Index
    currentIndex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data () {
    return {
      imageIndex: 0
    }
  },
  computed: {
    // Get Current Image
    getCurrentImage () {
      return this.images[this.imageIndex]
    }
  },
  mounted () {
    document.body.style.overflow = 'hidden'

    this.imageIndex = this.currentIndex
  },
  destroyed () {
    document.body.style.overflow = 'visible'
  },
  methods: {
    // Go To Image
    goToImage (index) {
      const newIndex = this.imageIndex + index

      if (newIndex >= 0 && newIndex < this.images.length) {
        this.imageIndex = newIndex
      }
    },
    // Close Slideshow
    closeSlideshow () {
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped lang="sass">
.base-image-slideshow
  @apply bg-shadow h-screen fixed flex flex-col justify-center left-0 text-white top-0 w-screen z-50

  &--close
    @apply absolute cursor-pointer m-3 right-0 text-3xl top-0

  &--title
    @apply mb-2 text-xl

  &--image
    animation: appearTop 0.3s ease-in
    @apply px-2 relative

    &--wrapper
      @apply flex items-center

    &--image
      @apply object-cover

      @screen xs
        height: 40vh

      @screen md
        height: 400px

    &--direction
      transition: all 0.2s ease-in
      @apply cursor-pointer flex items-center text-xl

      &:hover
        @apply text-grey-light

      i
        @apply mt-1 text-3xl

      &--disabled
        @apply text-grey-darker

        &:hover
          @apply text-grey-darker

</style>
