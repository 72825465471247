<template>
  <div
    id="app"
    :style="getPageStyles"
  >
    <!-- Loading State -->
    <div
      v-if="loadingRestaurant"
      class="flex h-screen items-center justify-center"
    >
      <BaseLoadingSpinner />
    </div>

    <!-- Widget View -->
    <div v-else-if="hasRestaurant && !disabled && !expired">
      <!-- Masthead Images -->
      <BaseImageSlider
        v-if="getRestaurantBackgroundImages.length && widgetShowImages"
        :images="getRestaurantBackgroundImages"
        @select="selectSliderImage"
      />

      <!-- Header Images Slideshow -->
      <BaseImageSlideshow
        v-if="showImageSlideshow"
        v-model="showImageSlideshow"
        :images="getRestaurantBackgroundImages"
        :current-index="selectedSlideshowImage"
      >
        <template #title>
          {{ getRestaurantName }}
        </template>
      </BaseImageSlideshow>

      <div class="current-view">
        <MenusMainView :show-header="true" />
        <MenusInfoView v-if="getCurrentTab === 'Info'" />
        <MenusMenusView v-else />
        <MenusFooter />
      </div>
    </div>

    <div
      v-else
      class="w-full h-screen relative flex justify-center unavailable-restaurant-block items-center"
    >
      <span class="text-2xl absolute z-50 lg:px-24 lg:py-20 xs:px-3 xs:py-4 shadow-xl rounded bg-white text-center">
        <span v-if="!hasRestaurant">
          This restaurant is currently unavailable.
        </span>
        <span v-else-if="disabled">
          This restaurant is currently disabled.
        </span>
        <span v-else-if="expired">
          This restaurant is expired.
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import MenusMenusView from '@/components/MenusMenusView.vue'
import MenusMainView from '@/components/MenusMainView.vue'
import MenusInfoView from '@/components/MenusInfoView.vue'
import MenusFooter from '@/components/MenusFooter.vue'
import BaseImageSlider from './components/globals/BaseImageSlider'
import BaseLoadingSpinner from './components/globals/BaseLoadingSpinner'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getRestaurantByInternalIdentifier } from './api/RestaurantsService'
import BaseImageSlideshow from './components/globals/BaseImageSlideshow'

export default {
  components: {
    BaseImageSlideshow,
    BaseLoadingSpinner,
    BaseImageSlider,
    MenusInfoView,
    MenusMainView,
    MenusMenusView,
    MenusFooter
  },
  props: {
    // Restaurant ID
    dataRestaurantId: {
      type: String,
      required: true
    },
    // Menu ID
    dataMenuId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loadingRestaurant: false,
      showImageSlideshow: false,
      selectedSlideshowImage: {},
      // Widget Settings
      widgetBackgroundColor: '#ffffff',
      widgetFont: 'Pulp Regular',
      widgetFontColor: '#000000',
      widgetShowImages: false
    }
  },
  computed: {
    ...mapGetters('MenusModule', [
      'hasRestaurant',
      'getRestaurantStatus',
      'getRestaurantName',
      'getRestaurantBackgroundImages',
      'getRestaurantStatus',
      'getRestaurantMembershipExpirationDate'
    ]),
    ...mapGetters('UIModule', [
      'getCurrentTab'
    ]),
    disabled () {
      return this.getRestaurantStatus === 'disabled'
    },
    expired () {
      if (this.getRestaurantMembershipExpirationDate) {
        return new Date() > this.getRestaurantMembershipExpirationDate
      } else return false
    },
    // Get Page Style
    getPageStyles () {
      return {
        fontFamily: this.widgetFont + ', sans-serif',
        backgroundColor: this.widgetBackgroundColor,
        color: `${this.widgetFontColor} !important`
      }
    }
  },
  async mounted () {
    await this.loadRestaurant()
    this.setDefaultMenu()
    this.connectFontAwesome()
  },
  methods: {
    ...mapActions('MenusModule', ['setDefaultMenu']),
    ...mapMutations('UIModule', ['SET_SHOW_HEADER']),
    ...mapMutations('MenusModule', ['SET_RESTAURANT', 'SET_SELECTED_MENU', 'SET_LEGEND_ITEMS']),
    connectFontAwesome () {
      const url = 'https://kit.fontawesome.com/0448ad8c55.js'
      const script = document.createElement('script')
      script.setAttribute('src', url)
      document.head.appendChild(script)
    },
    async loadRestaurant () {
      this.loadingRestaurant = true

      try {
        let restaurant = {}

        if (!this.dataRestaurantId) {
          restaurant = require('./assets/mock/restaurant.mock.js').default
          restaurant.activeMenu = restaurant.menus[0]
        } else {
          restaurant = await getRestaurantByInternalIdentifier(this.dataRestaurantId)
        }

        this.setWidgetSettings(restaurant.menuWidgetSettings)
        this.SET_LEGEND_ITEMS(restaurant.legendItems)
        this.SET_RESTAURANT(restaurant)
        this.SET_SELECTED_MENU(restaurant.activeMenu)
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingRestaurant = false
      }
    },
    // Set Widget Settings
    setWidgetSettings (settings) {
      if (!settings) return

      const { backgroundColor, font, fontColor, showHeader, showImages } = settings
      this.widgetBackgroundColor = '#' + backgroundColor
      this.widgetFont = font
      this.widgetShowImages = showImages

      this.SET_SHOW_HEADER(showHeader)

      document.documentElement.style.setProperty('--color-primary', '#' + fontColor)
    },
    // Select Slider Image
    selectSliderImage (image) {
      this.selectedSlideshowImage = image
      this.showImageSlideshow = true
    }
  }
}
</script>

<style lang="sass">
#app
  @apply flex flex-col min-h-screen h-full w-full

body
  -webkit-column-break-inside: avoid

.current-view
  @apply mx-auto

  @screen xs
    max-width: 100vw
    @apply w-full

  @screen md
    max-width: 80vw

</style>
