// Imports
import AxiosRestaurant from '../config/AxiosConfig'

const pregateResponse = (response) => {
  try {
    if (!response.data.data) return {}

    if (response.data) {
      return response.data.data
    }
  } catch (e) {
    if (e.response) {
      throw new Error(e.response.data.message)
    }
  }
}

// Gets restaurant by internal identifier
export const getRestaurantByInternalIdentifier = async (internalIdentifier) => {
  const url = `/public/restaurants/${internalIdentifier}`
  const response = await AxiosRestaurant.get(url)
  return pregateResponse(response)
}

// Get categories by restaurant's internal identifier and menu ID
export const getCategoriesByInternalIdentifierAndMenuId = async (internalIdentifier, menuId) => {
  const url = `/public/restaurants/${internalIdentifier}/menus/${menuId}`
  const response = await AxiosRestaurant.get(url)
  return pregateResponse(response)
}
