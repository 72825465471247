<template>
  <!-- Menus Restaurant Hours -->
  <div class="menus-restaurant-hours">
    <div class="pl-2 font-semibold text-lg">
      Hours of Operation
    </div>

    <div class="menus-restaurant-hours--card">
      <div
        v-for="day in days"
        :key="day.id"
        :class="{ 'font-bold': getToday === (day.name).toLowerCase() }"
        class="menus-restaurant-hours--card--item"
      >
        <!-- Day -->
        <div class="menus-restaurant-hours--card--item--day w-10">
          {{ day.name.substring(0, 3) | capitalize }}
        </div>

        <div v-if="parseDay(day.value).length">
          <div
            v-for="(time, index) in parseDay(day.value)"
            :key="index"
            class="menus-restaurant-hours--card--item--day ml-8"
          >
            {{ time | formatTime }}
          </div>
        </div>
        <div
          v-else
          class="text-black ml-8"
        >
          <span>Closed</span>
        </div>
        <!-- Open / Closed State -->
        <div
          v-if="parseDay(day.value).length && getToday === (day.name).toLowerCase()"
          class="ml-4"
        >
          <div
            v-if="isRestaurantOpen(parseDay(day.value))"
            class="text-green"
          >
            Open now
          </div>

          <div
            v-else
            class="text-danger"
          >
            Closed now
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import 'moment-duration-format'

export default {
  name: 'MenusRestaurantHours',
  filters: {
    capitalize (val) {
      if (!val) return

      return val[0].toUpperCase() + val.substring(1, val.length)
    },

    formatTime (val) {
      return `${val.open} - ${val.close}`
    }
  },
  data () {
    return {
      days: [
        { id: 0, name: 'Sunday', value: 0 },
        { id: 1, name: 'Monday', value: 1 },
        { id: 2, name: 'Tuesday', value: 2 },
        { id: 3, name: 'Wednesday', value: 3 },
        { id: 4, name: 'Thursday', value: 4 },
        { id: 5, name: 'Friday', value: 5 },
        { id: 6, name: 'Saturday', value: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters('MenusModule', ['getRestaurantHoursOfOperation', 'getRestaurantTimezone']),
    // Get Today
    getToday () {
      return momentTimezone(new Date(), 'YYYY-MM-DD h:mm:ss').format('dddd').toLowerCase()
    }
  },
  methods: {
    parseDay (day) {
      const timesOffered = this.getRestaurantHoursOfOperation.filter(time => time.dayOfWeek === day)
      return this.parseTimesOffered(timesOffered)
    },
    // Parse Times Offered
    parseTimesOffered (times) {
      const result = []

      // Loop through times in day
      for (let i = 0; i < times.length; i++) {
        const currentDay = times[i]

        result[i] = {
          ...currentDay,
          open: this.parseTimeToText(currentDay.open),
          close: this.parseTimeToText(currentDay.close)
        }
      }
      // This property used for execute autoupdate of time's
      // after it initialization
      return result
    },
    // Parse To Text Format
    parseTimeToText (hour) {
      return moment().startOf('day').add(hour, 'seconds').format('hh:mm A')
    },
    // Is Restaurant open
    isRestaurantOpen (hours) {
      let result = false

      for (let i = 0; i < hours.length; i++) {
        const begin = momentTimezone().tz(this.getRestaurantTimezone).startOf('day').add(hours[i].open, 'seconds')
        let end = null

        if (hours[i].open > hours[i].close) {
          end = momentTimezone().tz(this.getRestaurantTimezone).startOf('day').add(1, 'days').add(hours[i].close, 'seconds')
        } else {
          end = momentTimezone().tz(this.getRestaurantTimezone).startOf('day').add(hours[i].close, 'seconds')
        }

        const currentTime = momentTimezone().tz(this.getRestaurantTimezone)

        if (currentTime.isBetween(begin, end)) {
          result = true
        }
      }

      return result
    }
  }
}
</script>

<style scoped lang="sass">
.menus-restaurant-hours
  &--card
    @apply bg-white p-4

    &--item
      @apply border-b border-grey-light flex text-sm py-2

      &:last-child
        @apply border-0
</style>
